<template lang="html">
  <div id="Loader" ref="loader"></div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters(['pageData', 'pageList', 'pageListMeta']),
  },
  methods: {
    ...mapActions(['fetchItems']),
    initLoader() {
      const loader = this.$refs.loader;
      let options = {
        threshold: 0.1,
      };

      let observer = new IntersectionObserver(this.pushData, options);
      observer.observe(loader);
    },
    pushData(e) {
      if (e[0].isIntersecting) {
        this.pageData.options.items.query.p++;
        this.fetchItems({
          table: this.pageData.options.items.t,
          target: 'setPageList',
          query: this.pageData.options.items.query,
          action: 'push',
        });
      }
    },
  },
  created() {
    this.$nextTick(() => {
      this.initLoader();
    });
  },
};
</script>

<style lang="css" scoped>
#Loader {
  display: block;
  height: 300px;
  width: 100%;
  margin-top: -200px;
}
</style>
