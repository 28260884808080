<template>
  <div class="line" ref="line"></div>
</template>

<script>
export default {
  data() {
    return {
      observer: null,
    };
  },
  methods: {
    trigger(e) {
      const line = this.$refs.line;
      if (e[0].isIntersecting) {
        line.classList.add('active');
      }
    },
    createObserver() {
      this.observer = new IntersectionObserver(this.trigger, {
        threshold: 0.5,
      });

      this.observer.observe(this.$refs.line);
    },
  },
  mounted() {
    this.createObserver();
  },
};
</script>

<style lang="css" scoped>
.line {
  width: 50%;
  height: 30vh;
  border-right: 1px solid black;
  transition: height 2s;
  margin: 32px 0;
}

.active {
  height: 64px;
}

@media only screen and (min-width: 600px) {
  .line {
    width: 50%;
    height: 30vh;
    border-right: 1px solid black;
    transition: height 2s;
    margin: 0;
  }

  .active {
    height: 32px;
  }
}
</style>
