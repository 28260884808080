<template lang="html">
  <div v-if="pageList" class="Timeline">
    <Title spacer="true" />

    <!-- <div class="yt" v-if="pageData.content">
      <Preloader />
      <iframe
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
        type="text/html"
        src="https://www.youtube.com/embed/live_stream?channel=UCoYJawPz8kf1-rUZWzmtLew&autoplay=1&mute=1&showinfo=0&modestbranding=1&playsinline=1&controls=0&rel=0"
        ref="yt"
        allow="autoplay; encrypted-media"
      />
    </div> -->

    <div
      v-for="(item, index) in pageList"
      :key="item.id"
      class="item"
      ref="item"
    >
      <h1
        v-if="
          index == 0 ||
            (index > 0 &&
              formatDate(item.date_from) !=
                formatDate(pageList[index - 1].date_from))
        "
        class="month"
      >
        {{ formatDate(item.date_from) }}
      </h1>

      <Content :content="item.content" class="contentTL" :observe="true" />

      <Container
        v-if="item._container"
        v-for="container in item._container"
        :key="container.id"
        :container="container"
        :images="item._images"
        class="tlContainer"
        :observe="true"
      />

      <Liner v-if="index + 1 < pageList.length" />
      <div v-else class="dot"></div>
    </div>

    <Loader v-if="!pageListMeta.finished" />
  </div>
</template>

<script>
import Title from '@/components/Elements/Title.vue';
import Liner from '@/components/Elements/TimelineLine.vue';
import Content from '@/components/Elements/Content.vue';
import Container from '@/components/Container/Container.vue';
import ListEntry from '@/components/Container/ListEntry.vue';
import Loader from '@/components/Elements/Loader.vue';
import Preloader from '@/components/Elements/Preloader.vue';

import dayjs from 'dayjs';
const updateLocale = require('dayjs/plugin/updateLocale');
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
});

import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    Title,
    Content,
    Container,
    ListEntry,
    Loader,
    Preloader,
    Liner,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['pageData', 'pageList', 'pageListMeta']),
  },
  methods: {
    ...mapActions(['fetchItems']),
    formatDate: function(value) {
      if (value.split('-')[1] == '01' && value.split('-')[2] == '01') {
        value = dayjs(value).format('YYYY');
      } else {
        value = dayjs(value).format('MMMM YYYY');
      }
      return value;
    },
    showYT() {
      if (this.$refs.yt) this.$refs.yt.classList.add('showYT');
    },
  },
  created() {
    if (this.pageData.options.list) this.$store.state.hideFooter = true;
    this.fetchItems({
      table: this.pageData.options.items.t,
      target: 'setPageList',
      query: this.pageData.options.items.query,
    });

    //setTimeout(this.showYT, 2500);
  },
};
</script>

<style>
.Title {
}

.month {
  width: 100%;
  display: block;
  text-align: center;
  margin: 32px 0;
}

.item {
  position: relative;
}

.contentTL {
  border-bottom: none !important;
  width: 100%;
}

.dot {
  display: none;
}

.yt {
  position: relative;
  width: 100%;
  padding-top: 56%;
  margin-bottom: 32px;
  background: #222929;
  overflow: hidden;
}

.yt iframe {
  position: absolute;
  top: -5px;
  left: -5px;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  opacity: 0;
}

.yt .showYT {
  opacity: 1;
  transition: all 1s;
}

@media only screen and (min-width: 600px) {
  .dot {
    position: relative;
    display: block;
    width: 8px;
    height: 8px;
    top: 3px;
    background: black;
    border-radius: 50%;
    margin-left: calc(50% - 4px);
  }

  .contentTL {
    width: calc(50% - 16px);
    border-right: 1px solid black;
    margin: 0 !important;
    padding: 32px 0;
    display: grid;
    border-right: 1px solid black;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 24px;
    padding-right: 16px;
  }

  .contentTL > div {
    grid-column-start: 2;
    grid-column-end: 6;
  }

  .item:nth-child(even) .tlContainer {
    width: calc(50% - 32px);
    padding-right: 32px;
    border-right: 1px solid black;
  }

  .item:nth-child(odd) {
  }

  .item:nth-child(odd) .contentTL {
    border-left: 1px solid black;
    border-right: 0;
    margin-left: 50% !important;
  }

  .item:nth-child(odd) .tlContainer {
    margin-left: calc(50%);
    padding-left: 32px;
    border-left: 1px solid black;
  }

  .item:nth-child(odd) .contentTL > div {
    padding-left: 32px;
  }

  .tlContainer {
    margin-bottom: 0;
    padding-bottom: 32px;
  }
}
</style>
