<template lang="html">
  <div class="ListEntry Grid">
    <div class="ListYear">
      <span v-if="(prevEntry && entry.year != prevEntry.year) || !prevEntry">
        {{ entry.year }}
      </span>
    </div>
    <div class="ListContent">
      <div class="ListTitle">
        <a
          v-if="entry.link"
          :href="entry.link"
          target="top"
          title="Visit Website"
          class="ExternalLink"
          >{{ entry.title }}</a
        >
        <span v-else>{{ entry.title }}</span>
      </div>
      <div v-if="this.entry.content" class="ListContentContainer">
        <Content :content="this.entry.content" />
      </div>
      <div v-if="entry.description" class="ListDescription">
        {{ entry.description }}
      </div>
      <div v-if="entry.details" class="ListDetails">{{ entry.details }}</div>
      <div class="ListLocation">{{ entry.location }}</div>
      <div class="ListComment">{{ entry.comment }}</div>
    </div>
  </div>
</template>

<script>
import Content from '@/components/Elements/Content.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    Content,
  },
  props: {
    entry: null,
  },
  computed: {
    ...mapGetters(['pageList']),
    itemIndex() {
      return this.pageList.findIndex(item => item.id == this.entry.id);
    },
    prevEntry() {
      if (this.itemIndex > 0) return this.pageList[this.itemIndex - 1];
      else return null;
    },
    nextEntry() {
      if (this.itemIndex < this.pageList.length)
        return this.pageList[this.itemIndex + 1];
      else return null;
    },
  },
};
</script>

<style lang="css" scoped>
.ListEntry {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 24px;
}

.ListEntry .ListTitle {
  font-weight: bold;
}

.ListEntry .ListDescription,
.ListEntry .ListDetails {
  display: inline;
}

.ListEntry .ListDetails::before {
  content: ' — ';
}

.ListEntry .ListComment,
.ListEntry .ListLocation {
  font-style: italic;
}

.ListEntry .ListYear {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 3;
  font-weight: bold;
  font-size: 1rem;
  top: 1px;
  white-space: nowrap;
}

.ListEntry .ListContent {
  grid-column-start: 4;
  grid-column-end: 13;
  margin-bottom: 24px;
}

.ListEntry .Content {
  margin: 0;
}

@media only screen and (min-width: 600px) {
  .ListEntry .ListYear {
    font-size: 1.2rem;
  }

  .ListEntry .ListContent {
    grid-column-start: 3;
    grid-column-end: 12;
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 768px) {
  .ListEntry .ListContent {
    margin-bottom: 36px;
  }
}

@media only screen and (min-width: 900px) {
  .ListEntry .ListContent {
    grid-column-start: 3;
    grid-column-end: 8;
    margin-right: 24px;
  }
}
</style>
